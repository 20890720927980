import React from 'react'

const PreLoader = (props) => {
    return (
        <div className="preloade-box">
             <img width={60} src={process.env.REACT_APP_BASE_URL + 'images/logo-1723459912706.png'}  />
            <div id='loader'>
                {/* <img src={process.env.REACT_APP_BASE_URL + 'images/' + props.siteInfo.logo} alt={props.siteInfo.compName} title={props.siteInfo.compName} /> */}
            </div>
            {/* <div className="boxes">
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div> */}
        </div>
    )
}

export default PreLoader
